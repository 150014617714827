@font-face {
    font-family: 'RIUP-icons';
    src: url(./res/fonts/RIUP-icons.ttf);
}

@font-face {
    font-family: 'Rubik-Light';
    font-style: normal;
    font-weight: normal;
    src: url(./res/fonts/Rubik-Light.ttf);
}

@font-face {
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: normal;
    src: url(./res/fonts/Rubik-Regular.ttf);
}

@font-face {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: url(./res/fonts/NotoSans-Regular.ttf);
}

@font-face {
    font-family: 'Rubik-Medium';
    font-weight: normal;
    src: url(./res/fonts/Rubik-Medium.ttf);
}

@font-face {
    font-family: 'Rubik-SemiBold';
    font-weight: normal;
    src: url(./res/fonts/Rubik-SemiBold.ttf);
}

@font-face {
    font-family: 'Rubik-Light';
    src: url(./res/fonts/Rubik-Light.ttf);
}

@font-face {
    font-family: 'Rubik-Bold';
    font-weight: normal;
    src: url(./res/fonts/NotoSansGeorgian-Bold.ttf);
}

@font-face {
    font-family: 'NotoSansGeorgian-Light';
    font-style: normal;
    font-weight: normal;
    src: url(./res/fonts/NotoSansGeorgian-Light.ttf);
}

@font-face {
    font-family: 'NotoSansGeorgian-Regular';
    font-style: normal;
    font-weight: normal;
    src: url(./res/fonts/NotoSansGeorgian-Regular.ttf);
}

@font-face {
    font-family: 'NotoSansGeorgian-Medium';
    font-weight: normal;
    src: url(./res/fonts/NotoSansGeorgian-Medium.ttf);
}

@font-face {
    font-family: 'NotoSansGeorgian-SemiBold';
    font-weight: normal;
    src: url(./res/fonts/NotoSansGeorgian-SemiBold.ttf);
}

@font-face {
    font-family: 'NotoSansGeorgian-Bold';
    font-weight: normal;
    src: url(./res/fonts/NotoSansGeorgian-Bold.ttf);
}

html {
    --antd-wave-shadow-color: #109DFB;
}

html * {
    font-family: Rubik-Regular, NotoSansGeorgian-Regular;
}


element.style {
}
.ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    transition: background .3s,border .3s;
}
*, :after, :before {
    box-sizing: border-box;
}
* {
    font-family: Rubik-Regular;
}

div {
    display: block;
}
.ant-picker-content td, .ant-picker-content th {
    position: relative;
    min-width: 24px;
    font-weight: 400;
}
.ant-picker-cell-in-view {
    color: rgba(0,0,0,.85);
}
.ant-picker-cell {
    padding: 3px 0;
    cursor: pointer;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content, .ant-picker-panel-container .ant-picker-panel table {
    text-align: center;
}
.ant-picker-content {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}
table {
    border-collapse: collapse;
}

table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
}
.ant-picker-panel {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    outline: none;
}
.ant-picker-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    z-index: 1050;
}
body {
    margin: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #fff;
    position: relative;
    font-feature-settings: "tnum";
}
html {
    --antd-wave-shadow-color: #1890ff;
    --scroll-bar: 0;
}
html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
html {
    --antd-wave-shadow-color: #109DFB !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border-color: #109DFB !important;
}

.ant-picker-today-btn {
    color: #109DFB !important;
}

ant-picker-cell::selection {
    background: #109DFB !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #109DFB !important;
}

body {
    margin: 0px;
}

* {
    font-family: Rubik-Regular;
}

th.ant-table-cell {
    padding: 23px 0;
}

th.ant-table-cell:before {
    display: none;
}

.ant-table-cell {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
}

.ant-table-cell.ant-table-selection-column {
    padding-left: 20px !important;
}

.ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-indeterminate .ant-checkbox-inner:after, .ant-checkbox-indeterminate {
    background: linear-gradient(180deg, #4A88FF 18%, #5A56FD 106%) !important;
    border-color: #4986FD !important;
}

.ant-picker-now-btn {
    color: #109DFB !important;
}

.ant-btn.ant-btn-primary.ant-btn-sm {
    border-radius: 7px;
    background: linear-gradient(180deg, #4A88FF 18%, #5A56FD 106%) !important;
    border: none !important;
}

.ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #4986FD !important;
    border-radius: 4px !important;
}

.ant-checkbox-checked:after {
    border-color: #4986FD !important;
    border-radius: 4px !important;
}

.ant-checkbox-inner {
    border-radius: 4px !important;
}

.ant-spin-dot-item {
    background-color: #109DFB !important;
}

.ant-modal-close-x {
    display: none !important;
}

.ant-checkbox-wrapper {
    margin-left: 0 !important;
}

.ant-table-cell.ant-table-selection-column {
    display: block;
    padding-left: 14px !important;
}

.fct > td {
    height: 50px;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.fct > td.ant-table-selection-column {
    display: flex;
    align-items: center;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:active, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:hover {
    color: #fff !important;
}

.ant-notification {
    bottom: 0px !important;
}

.ant-select-dropdown {
    background-color: #fff !important;
    border: 1px solid #DCDFE6 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22) !important;
    border-radius: 4px !important;
    width: auto !important;
}

div:not(.ant-table-content):not(.show-scrollbar)::-webkit-scrollbar {
    display: none;
}

div:not(.ant-table-content):not(.show-scrollbar) {
    scrollbar-width: none;
}

.rbc-calendar div:not(.ant-table-content):not(.show-scrollbar) {
    overflow: scroll;
}

body > div {
    z-index: 100000;
}

.ant-select-selection-item {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.react-tags {
    display: flex;
    flex-direction: column;
    border: 1px solid #DCDFE6 !important;
    outline: none !important;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 13px;
    min-height: 36px;
    background: #fff;
    min-width: 162px;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;
}

.react-tags__selected {
    min-width: 162px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.react-tags__selected > *:first-child {
    padding-top: 6px;
}

.react-tags__search-input {
    border: none;
    min-width: 162px;
    align-items: center;
    line-height: 100%;
    height: 22px;
}

.react-tags__search-wrapper {
    height: 36px;
    display: flex;
    align-items: center;
}

.react-tags__search-input:focus {
    border: none;
    outline: none;
    display: flex;

}

.react-tags__suggestions {
    background-color: #fff;
    padding: 0px;
    padding-top: 16px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    max-height: 50vh !important;
    overflow-y: scroll;
    position: absolute;
}

.react-tags__suggestions ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    overflow-y: auto;
}

.react-tags__selected {
    max-height: 50vh !important;
    flex-wrap: nowrap !important;
    overflow-y: auto !important;
}

.react-tags__suggestions li {
    cursor: pointer;
    display: block;
    text-align: left;
    padding: 6px 14px;
}

.react-tags__suggestions mark {
    padding: 0px;
    margin: 0px;
    background: #fff;
}

.ant-slider-rail {
    height: 2px !important;
    background: #303133 !important;
    border-radius: 0px !important;
}

.disabled-row {
    opacity: 0.4 !important;
}

.error-row {
    background: #FAF2F1;
}

.error-row .ant-table-cell-fix-left, .error-row .ant-table-cell-fix-right {
    background: #FAF2F1;
}

.ant-tooltip {
    width: auto !important;
    z-index: 40000 !important;
}

.ant-modal-mask {
    z-index: 12333 !important;
}

.ant-modal-wrap {
    z-index: 99999 !important;
    display: flex;
    overflow: hidden !important;
    justify-content: center;
    width: 100vw;
}

.ant-modal.wide {
    margin: 0px 0px !important;
    width: auto !important;
}

.ant-table-cell {
    white-space: nowrap !important;
}

.ant-modal-body {
    background: transparent !important;
    overflow: hidden;
}

.ant-modal-content {
    background: transparent !important;
    overflow: hidden;
}

.ant-modal-wrap.right {
    justify-content: flex-end !important;
}
.ant-modal-wrap.mwfs {
    justify-content: flex-start !important;
}

.ant-modal-wrap.right .ant-modal {
    margin: 0 !important;
}

.cnu .ant-select-selection-item {
    background-color: #1E386A !important;
    border-radius: 6px !important;
    color: #FFFFFF !important;
}

.cnu .ant-select-selector {
    padding: 3px !important;
}

.cnu .ant-select-selection-item-remove {
    color: #FFFFFF !important;
}

.cnu-dropdown .ant-select-item {
    background-color: #FFFFFF !important;
    border-bottom: 1px solid #DCDFE6;
    height: 55px;
    min-height: 55px;
    max-height: 55px;
}

.cnu-dropdown {
    border-radius: 9px !important;
    border: 1px solid #DCDFE6 !important;
    box-sizing: border-box !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.22) !important;
    padding: 0 !important;
}

.cnu-dropdown .ant-select-item-option-state {
    align-self: center !important;
}

.cnu-dropdown .ant-select-item-option-content {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
}

.onboard-disable-modal {
    z-index: 999999999 !important;
}

.reg-check .ant-checkbox-inner {
    border: 1px solid #303133 !important;
}

.ant-select-item-option-selected {
    background: #f5f5f5 !important;
}

.wpi .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-left: 0 !important;
}

.wpi .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.wpi .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    top: 39.8% !important;
}

.ant-notification-notice {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    border: 0px solid transparent !important;
    border-left-width: 6px !important;
    padding: 12px 8px !important;
    max-width: 260px !important;
}

.ant-notification-notice.ant-notification-notice-error {
    border-left-color: #C8402F !important;
    border-left-width: 6px !important;
}

.ant-notification-notice.ant-notification-notice-success {
    border-left-color: #24993E !important;
    border-left-width: 6px !important;
}

.ant-notification-notice .ant-notification-notice-message {
    font-size: 14px !important;
    line-height: 100% !important;
    margin-left: 28px !important;
}

.ant-notification-notice.ant-notification-notice-error .ant-notification-notice-message {
    color: #C8402F !important;
}
.ant-notification-notice.ant-notification-notice-success .ant-notification-notice-message {
    color: #24993E !important;
}

.ant-notification-notice .ant-notification-notice-description {
    font-size: 12px !important;
    line-height: 14px !important;
    color: #303133 !important;
    margin-left: 28px !important;
}

.ant-notification-notice-icon {
    margin-left: 0px !important;
    line-height: 18px !important;
}

.ant-notification-notice-close {
    top: 10px !important;
    right: 10px !important;
    line-height: 10px !important;
    color: #909399 !important;
}

.ant-notification.ant-notification-bottom-right {
    margin-right: 10px !important;
}

.ant-picker-dropdown-range .ant-picker-panel-container {
    display: flex !important;
    flex-direction: row-reverse !important;
    border-radius: 10px !important;
}

.ant-picker-dropdown-range .ant-picker-footer {
    min-width: 40px !important;
    border-right: 1px solid #f0f0f0 !important;
    padding: 16px 10px !important;
}

.ant-picker-preset > .ant-tag.ant-tag-blue {
    background: transparent !important;
    color: #303133 !important;
    border: none !important;
    font-size: 14px !important;
    line-height: 100% !important;
    padding: 10px 0 !important;
}

.ant-picker-range .ant-picker-active-bar {
    background-color: #109DFB !important;
}

.ant-picker .ant-picker-range {
    background-color: #E5E8ED !important;
}

.ant-picker-cell {
    /*padding: 1px 0 !important;*/
}

.ant-picker-dropdown-range .ant-picker-cell-inner {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 36px !important;
}

.ant-picker-dropdown-range .ant-picker-cell:before {
    height: 36px !important;
}

.ant-picker-dropdown-range .ant-picker-cell:after {
    height: 36px !important;
    width: 36px !important;
}

.ant-picker.ant-picker-borderless {
    border: none !important;
    background-color: #E5E8ED !important;
}

.ant-picker-range .ant-picker-clear {
    background: transparent !important;

}

.ant-picker-range .ant-picker-clear svg {
    fill: #909399 !important;
}

.srp .ant-picker-clear {
    background: linear-gradient(180deg,#DCDFE6 0%,#CDD3DF 100%) !important;
}

.ace_editor, .ace_editor *{
    font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
}

.ant-drawer .ant-drawer-content {
    border-radius: 10px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.ant-select-selector {
    box-shadow: none !important;
}

.all-contacts .ant-table-cell.ant-table-selection-column {
    height: 69px !important;
    padding-top: 20px;
}

.ant-select-selection-item{
    padding-left: 0 !important;
}

.ant-select-arrow {
    width: auto !important;
}

.sc-fuRDZQ.dqvBcR{

    top: 3px !important;
    right: 0 !important;
}

.ant-modal {
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
    top: 0px !important;
}

.ant-modal-content {
    width: 100% !important;
}

.ant-tooltip-inner {
    border-radius: 6px !important;
    background-color: #303133 !important;
}

.ant-tooltip-arrow-content {
    background-color: #303133 !important;
}

#drawer-container > section > section {
    flex: 0 1 auto !important;
}

#drawer-container > section {
    min-width: 1352px !important;
    width: 100% !important;
}

#drawer-container > section > section > div > div > div:nth-child(4) > div {
    height: 90% !important;
}

#drawer-container > section > section {
    min-width: 600px !important;
    flex-grow: 1 !important;
}

span.ant-select-selection-placeholder {
    line-height: 30px !important;
    display: flex !important;
    align-items: center !important;
}

#drawer-container > section > aside.arco-resizebox.arco-layout-sider.arco-layout-sider-light > div.arco-layout-sider-children > div > div > div:nth-child(1) > div > div:nth-child(1) > div > div._BlocksPanel_15fho_1 {
    bottom: 20px;
    left: 67px !important;
    overflow: hidden;
    border-radius: 10px !important;
}

#drawer-container > section {
    /*border-radius: 10px !important;*/
}

.contacts-slider .ant-slider-rail {
    background: #DCDFE6 !important;
    height: 2px !important;
    margin-top: 1px !important;
}

.contacts-slider .ant-slider-dot {
    background-color: #DCDFE6 !important;
    border-color: #DCDFE6 !important;
}

.contacts-slider .ant-slider-dot-active.ant-slider-dot {
    background-color: #fff !important;
    border-color: #109DFB !important;
}

.contacts-slider .ant-slider-track {
    background: #109DFB !important;
}

.contacts-slider .ant-slider-handle {
    border-color: #109DFB !important;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50% !important;
    margin-top: -6px !important;
}

.contacts-slider .ant-slider-mark {
    top: -20px !important;
    font-size: 12px !important;
    line-height: 100% !important;
}

.contacts-slider .ant-slider-mark > span {
    color: #109DFB !important;
}

.ant-input:hover {
    border-color: #109DFB !important;
}

.ant-input:focus {
    border-color: #109DFB !important;
}

.new .ant-modal-content {
    box-shadow: none !important;
    background-color: transparent !important;
}

.ant-select-disabled > span > div {
    background: #f5f5f5 !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.oht {
    padding: 10px !important;
    max-width: 300px !important;
    width: 300px !important;
}

.oht-text {
    font-family: Rubik-Medium;
    font-weight: 500;
}

.oht .ant-tooltip-inner {
    padding: 8px 25px 8px 25px !important;
    color: #303133 !important;
    font-weight: 500;
    background: #F9FBFF !important;
    border-radius: 9px !important;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12) !important;
    border: 1px solid #109DFB;
    max-width: 300px !important;
    text-align: center !important;
}

.oht .ant-tooltip-arrow {
    display: none !important;
}

.ant-select-disabled > span > div > span {
    background: #f5f5f5 !important;
}

.ant-spin .ant-spin-spinning {
    max-height: 1000px !important;
}

.ant-spin-nested-loading>div>.ant-spin {
    max-height: 1000px !important;

}

.disabled-role-checkbox {
    /*cursor: not-allowed !important;*/
    pointer-events: none;
}

.disabled-role-checkbox input {
    /*cursor: not-allowed !important;*/
    pointer-events: none;
}

@media screen and (max-width: 1200px) {
    .event-search-select .ant-select-selector {
        max-width: 250px;
    }
}

#questionnaire_slider .ant-slider-rail {
    height: 8px !important;
    border-radius: 0px !important;
    background: #fff !important;
}
#questionnaire_slider .ant-slider-track {
    height: 8px !important;
    border-radius: 0px !important;
    background: linear-gradient(180deg, #4A88FF 18%, #5A56FD 106%) !important;
}

#questionnaire_slider .ant-slider-dot {
    height: 26px !important;
    width: 26px !important;
    background: #fff !important;
    border-width: 0px !important;
    top: -9px;
    margin-left: -13px;
}

#questionnaire_slider .ant-slider-handle {
    background: #5B3EF5 !important;
    border-color: #4A88FF !important;
    width: 26px !important;
    height: 26px !important;
    border-width: 4px !important;
    margin-top: -9px;
}

#questionnaire_slider .ant-slider-dot-active.ant-slider-dot {
    background: linear-gradient(180deg, #4A88FF 18%, #5A56FD 106%) !important;
    display: none !important;
}

.ant-drawer {
    z-index: 100 !important;
}

.apx-custom-tt {
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
    border-radius: 9px;
    font-family: Rubik-Regular;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
}

.apx-custom-tt-title {
    font-size: 13px;
    line-height: 120%;
    color: #909399;
}

.apx-custom-tt-total {
    font-size: 10px;
    line-height: 100%;
    color: #303133;
    margin-top: 14px;
}

.apx-custom-tt-exeeded {
    font-size: 10px;
    line-height: 100%;
    color: #C8402F;
    margin-top: 6px;
}

.apx-custom-tt-ws {
    display: flex;
    margin-top: 12px;
    align-items: center;
    font-size: 10px;
    line-height: 100%;
    color: #303133;
}

.apx-custom-tt-ws-marker {
    border-radius: 2px;
    margin-right: 5px;
    width: 12px;
    height: 12px;
}

.disable-default-apx-tooltips .apexcharts-tooltip.apexcharts-theme-light {
    border: none !important;
    background: transparent !important;
}

.apexcharts-data-labels{ display:contents !important; }

.rbc-month-view *:not(.rbc-event) {
    border-color: #DCDFE6 !important;
}

.rbc-event:focus {
    outline: 5px auto #303133 !important;
}

.rbc-month-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.sc-hQxkJl{
    margin-bottom: -6px;
}

.rbc-month-header * {
    border: none;
}

.rbc-date-cell, .rbc-header {
    font-size: 12px;
    margin-top: 6px;
    line-height: 100%;
    color: #909399;
}

.rbc-day-bg.rbc-off-range-bg {
    background: #e6e6e659;
}

.rbc-today {
    box-shadow: inset 0px 0px 10px rgba(40, 168, 254, 0.5);
    background: #FFFFFF;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
    /*box-shadow: 0px 11px 8px -10px rgba(0, 0, 0, 0.2), 0px -11px 8px -10px rgba(0, 0, 0, 0.2);*/
    /*box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),*/
    /*0 10px 20px -5px rgba(115,115,115,0.75);*/
}

.resize-textarea {
    border: 1px solid #DCDFE6 !important;
    border-radius: 9px;
    padding-right: 24px;
    padding-left: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    resize: none;
    font-size: 13px;
    line-height: 120%;
    outline: none;


    --antd-wave-shadow-color: #109DFB !important;
}

.resize-textarea:disabled {
    cursor: not-allowed;
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0,0,0,.25);
}

.resize-textarea:focus {
    /*border-color: #109DFB !important;*/
    border: 1px solid #109DFB !important;
}

.resize-textarea:hover {
    /*border-color: #109DFB !important;*/
    border: 1px solid #109DFB !important;
}

.resize-textarea:focus-visible {
    /*border-color: #109DFB !important;*/
    border: 1px solid #109DFB !important;
}

.resize-textarea:focus-within {
    /*border-color: #109DFB !important;*/
    border: 1px solid #109DFB !important;
}

.rbc-date-cell {
    margin-bottom: 4px;
}

.ant-drawer {
    z-index: 100 !important;
}

.ant-select-dropdown {
    border-radius: 10px !important;
}

.ant-select-dropdown.show-types {
    width: auto !important;
}

.esdev-app .esdev-option-panel {
    z-index: 0 !important;
}

.flow-edge-remove {
    transition: opacity 280ms ease;
    /*opacity: 0;*/
}

.flow-edge-remove:hover {
    /*opacity: 1;*/
}

.flow-edge {
    cursor: pointer;
}

.ant-modal-root {
    z-index: 99999999;
}


.ant-modal-body {
    border-radius: 15px 15px 13px 13px !important;
    overflow: visible !important;
}

.ant-modal-content {
    border-radius: 15px 15px 13px 13px !important;
    overflow: visible !important;
}

#apexchartsbasicxbar > svg {
    overflow: visible;
}

.ant-table-cell.ant-table-selection-column {
    height: 61px !important;
}

.submenu {
    margin: 0 !important;
    border-radius: 10px !important;
}

.submenu .ant-dropdown-menu {
    margin: 0 !important;
    border-radius: 10px !important;
}
.ant-dropdown-menu.ant-dropdown-menu-sub.ant-dropdown-menu-vertical{
    border-radius: 10px !important;
}
.no-padding {
    padding: 0px !important;
}

.nopad .ant-tooltip-inner {
    padding: 0px !important;
}

.ttc-chart .ant-tooltip-inner {
    padding: 0px !important;
    background: transparent !important;
    box-shadow: none !important;
}

.ace_scroller .ace_scroll-left {
    overflow-x: auto;
}

span.prefix {
    color: #909399 !important;
}

.apexcharts-canvas.apexchartsbasic-bar > svg {
    overflow: visible !important;
}

.tag-input-container .ant-select-selection-item {
    border-radius: 4px !important;
    background-color: transparent !important;
    border: none !important;
}

.tag-input-container .ant-select-selection-overflow {
    flex-wrap: nowrap !important;
}

.workflow-event-autocomplete .ant-select-selection-overflow {
    max-width: 168px !important;
}

.workflow-event-autocomplete .ant-select-selector {
    padding-right: 0 !important;
}


.tag-input-container .ant-select-selection-item .ant-select-selection-item-content {
    /*color: #fff !important;*/
}

html * {
    padding: 0;
    border: 0;
    margin: 0;
    font-family: Rubik-Regular;
    font-size: 100%;
    vertical-align: baseline;
}

.cds--cc--scatter {

}

.rmv-fill .graph-frame > svg > svg.cds--cc--area {
    display: none !important;
}

.ccc-donut-container .callouts {
    display: none !important;
    opacity: 0 !important;
}

.ccc-donut-container .donut-title {
    font-family: Rubik-Regular;
    line-height: 120% !important;
    color: #109DFB !important;
    fill: #109DFB !important;
}

div.layout-child.spacer {
    height: 15px !important;
}

.dashboard-analytics p.title {
    display: none;
}

.cds--btn .cds--btn--primary {
    width: 62px !important;
    height: 26px !important;
    padding: 6px 20px !important;
    line-height: 14px !important;
    cursor: pointer !important;
    font-size: 12px !important;
    background-color: linear-gradient(rgb(74, 136, 255) 18%, rgb(90, 86, 253) 106%) !important;
}

.cds--modal.is-visible .cds--modal-container {
    border-radius: 10px !important;
}

.cds--cc--tooltip {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22) !important;
    border-radius: 9px !important;
    padding: 12px 10px !important;
    min-width: 210px !important;
    padding-bottom: 0px !important;
}

.sac-date {
    display: flex;
    justify-content: space-between;
}

.sac-date > div {
    font-family: Rubik-Regular;
    font-size: 10px;
    line-height: 100%;
    color: #909399;
    margin-bottom: 10px;
}

.sac-group-row {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.sac-group-legend-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 6px;
}

.sac-group-legend-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sac-group-legend-title > div {
    font-family: Rubik-Regular;
    font-size: 13px;
    line-height: 120%;
    color: #303133;
}

.header.cds--cc--layout-row {
    transform: translateX(20px) !important;
}

.cds--cc--legend {
    position: absolute;
    top: 8px;
    right: 30px;
}

div.layout-child.spacer {
    display: none;
}

.full-frame.cds--cc--layout-column {
    margin-top: 12px;
}

.cds--overflow-menu__trigger {
    background: #fff;
    border-color: #fff;
    transition-duration: 0s !important;
}

.cds--overflow-menu__trigger:hover {
    background: #fff;
    border-color: #fff;
    transition-duration: 0s !important;
}

.cds--overflow-menu__trigger:active {
    background: #fff;
    border-color: #fff;
    transition-duration: 0s !important;
    box-shadow: none !important;
}

.toolbar-control.cds--overflow-menu.cds--overflow-menu--open {
    box-shadow: none !important;
    background: #fff !important;
}

.cds--overflow-menu-options.cds--overflow-menu--flip.is-open {
    background: #fff;
    border-radius: 9px;
    overflow: hidden;
}

.cds--cc--toolbar {
    box-shadow: none !important;
    background: #fff !important;
}

.layout-child.toolbar {
    box-shadow: none !important;
    background: #fff !important;
}

.cds--overflow-menu.cds--overflow-menu--open, .cds--overflow-menu.cds--overflow-menu--open .cds--overflow-menu__trigger {
    box-shadow: none !important;
    background: #fff !important;
}

.cds--overflow-menu-options__btn.cds--overflow-menu-options__btn {
    outline: none;
}

.cds--overflow-menu-options__btn.cds--overflow-menu-options__btn:active {
    outline: none;
}

.cds--overflow-menu-options__btn.cds--overflow-menu-options__btn:focus {
    outline: none;
}

.cds--cc--threshold--label {
    border-radius: 9px;
}

.legend-item .checkbox {
    border-radius: 50% !important;
    width: 15px !important;
    height: 15px !important;
}

.ant-skeleton {
    height: auto !important;
    min-height: auto !important;
    display: flex !important;
    flex: 0 !important;
    flex-shrink: 1 !important;
    flex-grow: 0 !important;
    width: auto !important;
}

body > div.zsiq_floatmain {
    z-index: 9999 !important;
    transform: scale(0.8) translate3d(6px, 6px, 0);
}

.ant-dropdown-menu-title-content {
    font-size: 13px !important;
}

/*.ant-modal-root .ant-modal-mask {*/
/*    z-index: 999999 !important;*/
/*}*/

.cds--cc--tooltip {
    padding-bottom: 12px !important;
}

.rbc-month-view {
    border-bottom: none !important;
    border-left: none !important;
}

.ant-notification.ant-notification-bottom-right {
    z-index: 9999999 !important;
}

.public-DraftStyleDefault-block {
    display: flex;
}

.rte-editable {
    flex-direction: column;
}

.rte-editable > p {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;
    min-width: 140px;
    margin-bottom: 0px !important;
}

.rte-editable > p > span {
    display: inline;
    /*white-space: nowrap;*/
    padding: 1px;
}

.rdrDateDisplayWrapper {
    display: none !important;
}

.rdrInputRanges {
    display: none !important;
}

.rdrStaticRangeLabel {
    font-size: 14px;
    line-height: 16.59px;
    font-weight: 400;
}

.ant-picker-dropdown-range {
    display: none;
}

.date-picker-comp {
    display: none !important;
}

.range-picker-day.ant-picker-dropdown {
    display: none;
}

.range-picker-day.ant-picker-dropdown {
    display: none;
}

.ant-picker-date-panel {
    /*display: none !important;*/
}

.old-date-picker .ant-picker-date-panel {
    display: block !important;
}

.ant-picker-week-panel {
    display: none !important;
}

.rdrDefinedRangesWrapper {
    border-top-left-radius: 10px !important;
    /*border-bottom-right-radius: 10px !important;*/
}

.rdrDefinedRangesWrapper {
    border-top-left-radius: 10px !important;
    /*border-bottom-left-radius: 10px !important;*/
}

.rdrCalendarWrapper {
    border-top-right-radius: 10px !important;
    /*border-bottom-right-radius: 10px !important;*/
}

.rdrDateRangePickerWrapper {
    /*box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 16px !important;*/
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rdrStaticRange {
    border-top-left-radius: 10px !important;
    border-bottom: none !important;
    padding: 5px 0;
}

.rdrStaticRangeSelected {
    border-top-left-radius: 10px !important;
}

.rdrStaticRanges {
    margin-top: 10px;
}

.customers-row-sg .ant-table-selection-column {
    height: 65px !important;
}

.greytext {
    color: #909399;
}
