.ant-popover-inner {
    background: #041638 !important;
    border-radius: 2px;
}

.wsp {
    top: -5px !important;
    left: 44px !important;
}

.wsp.opnd {
    top: 48px !important;
    left: 0 !important;
}

.wsp .ant-popover-inner {
    background: #010D24 !important;
}

.wsp-demo {
    margin-left: 8px !important;
}

.wsp-demo .ant-popover-content {
    background: #010D24 !important;
    border-radius: 10px !important;
    margin-left: 8px !important;
}

.wsp-demo .ant-popover-inner-content {
    background: #010D24 !important;
}

.stt {
    left: 48px !important;
}

.stt .ant-popover-inner {
    background: transparent !important;
    box-shadow: none !important;
}

.ant-popover-title {
    color: #DCDFE6 !important;
    border-color: #909399 !important;
}

.ant-popover-content {
    background: #041638 !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
    border-radius: 0 10px 10px 0;
    padding: 12px;
}

.stt .ant-popover-content {
    padding: 0 !important;
    background: transparent !important;
}

.wsp .ant-popover-content {
    background: #010D24 !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
    border-radius: 0 8px 8px 0;
    padding: 7px 12px;
    margin-top: 4px;
    margin-left: 4px;
}

.wsp .ant-popover-content {
    background: #010D24 !important;
    max-height: 100vh !important;
    overflow-y: auto;
}
.wsp.ant-popover.opnd {
    width: 220px !important;
    padding-top: 0px !important;
    border-radius: 0px !important;
    margin: 0px !important;
}

.wsp.ant-popover.opnd .ant-popover-content {
    margin: 0px !important;
    max-height: calc(100vh - 60px) !important;
    border-radius: 0px !important;
}

.ant-popover-arrow > span {
    display: none;
}

.ant-popover-inner-content {
    padding: 0px !important;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #109DFB !important;
    color: #FFFFFF !important;
}

.ant-picker-cell .ant-picker-cell-inner {
    border-radius: 4px !important;
}

.stt .ant-popover-inner-content {
    color: #fff !important;
}

.ant-popover-placement-right, .ant-popover-placement-rightBottom, .ant-popover-placement-rightTop {
    padding-left: 0px !important;
}
