.App {
    font-family: sans-serif;
    padding: 20px;
}

.stripo {
    display: flex;
    flex-direction: column;
}

.stripo__header {
    position: absolute;
    right: 16px;
    top: 0;
    display: flex;
    z-index: 1040;
    padding: 10px;
}

.stripo__content {
    display: flex;
    width: 100%;
    min-height: 90vh;
}

#stripoSettingsContainer > div {
    min-height: 90vh;
    max-height: 90vh;
    overflow-y: auto;
}

#stripoPreviewContainer > div {
    background: transparent;
}

#stripoPreviewContainer > div > iframe {
    height: 90vh;
    max-height: 90vh;
    overflow-y: auto;
    margin-right: 0px !important;
}

#stripoSettingsContainer {
    flex: 0 0 400px;
    border-right: 1px solid #ccc;
}

#stripoPreviewContainer {
    flex: 1;
    background: rgb(246 246 246);
}
